import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';

import imageIllustrationPrivacyPolicy from '../assets/images/illustrations/Privacy_policy.png';

const PrivacyPage = () => (
  <>
    <SEO title="PPS Terms and Conditions" />
    <div className="common-page privacy-page">
      <HeadingSection h1="PPS Terms and Conditions" image={imageIllustrationPrivacyPolicy} />
      <SecondSection />
    </div>
  </>
);

const SecondSection = () => (
  <section className="second-section">
    <div className="container">
      <h4>Important information you need to know about this Agreement</h4>
      <p>
        Please read this Agreement carefully before you activate your Account or use any of our
        services. This information forms the agreement for your SPRIVE Account including any
        associated payment services that we may provide to you. By confirming that you accept the
        terms of this Agreement, or using our services, you accept the terms of this Agreement,
        which shall apply to the scope of services as and when applicable. If there is anything you
        do not understand, please contact Customer Services using the contact details in paragraph
        19 of this Agreement.
      </p>
      <h4>1. Definitions</h4>
      <p>
        <ol>
          <p>
            <strong>Account</strong> means your electronic money account(s) held with PPS which is
            accessible via the App.
          </p>
          <p>
            <strong>Account Details</strong> means any details related to your Account, such as, but
            not limited to, Sort Code and Account Number.
          </p>
          <p>
            <strong>Account Information Services</strong> means an online service that provides
            consolidated information on accounts held by you with one or more payment service
            providers such as banks.
          </p>
          <p>
            <strong>Account Information Services Provider</strong> means a third-party payment
            service provider, which is authorised by its Regulator, to provide Account Information
            Services with your explicit consent, and under a separate agreement that you have signed
            with them.
          </p>
          <p>
            <strong>Account Limits</strong> means maximum limits that you can have in relation to
            the Account, such as the Account Maximum Balance and limits on transactions as referred
            to in paragraph 2.
          </p>
          <p>
            <strong>Account Maximum Balance</strong> means the maximum balance you can have on your
            Account as referred to in paragraph 2.
          </p>
          <p>
            <strong>Account Number</strong> means your unique personal account number associated
            with your Account, and is found in your App.
          </p>
          <p>
            <strong>Account Owner</strong> means you, the individual legally responsible for an
            Account.
          </p>
          <p>
            <strong>Agreement</strong> means this agreement as varied from time to time.
          </p>
          <p>
            <strong>App</strong> means the SPRIVE App provided to you by the Programme Manager in
            accordance with the Programme Manager Terms.
          </p>
          <p>
            <strong>Authorised</strong> Third Party Provider means an Account Information Service
            Provider and/or a Payment Initiation Service Provider.
          </p>
          <p>
            <strong>Automatic Payments</strong> means Direct Debits, Standing Orders and Future
            Dated Payments.
          </p>
          <p>
            <strong>Available Balance</strong> means the value of available funds in your Account.
          </p>
          <p>
            <strong>BACS Credit</strong> means BACS Direct Credit being a service enabling
            organisations to make payments to an account, which takes three Working Days for the
            funds to be cleared.
          </p>
          <p>
            <strong>CHAPS</strong> means Clearing House Automated Payments System, a bank-to-bank
            technology which enables same-day payments to be made within the UK, provided the
            payment instruction is received by the sending bank or organisation before a certain
            time.
          </p>
          <p>
            <strong>Customer Services</strong> means the team responsible for supporting queries
            relating to your Account. Contact details for Customer Services can be found in
            paragraph19.
          </p>
          <p>
            <strong>Data Protection Laws</strong> means the Data Protection Act 2018, General Data
            Protection Regulation (GDPR) (Regulation (EU) 2016/679) and any applicable replacement
            laws or regulations as may be in force and applicable, from time to time.
          </p>
          <p>
            <strong>Direct Debit</strong> means a service allowing a third-party to collect
            pre-authorised funds from your Account electronically on agreed dates, for example to
            pay bills.
          </p>
          <p>
            <strong>EEA</strong> means the European Economic Area, which currently includes all
            countries in the European Union together with Iceland, Norway and Liechtenstein.
          </p>
          <p>
            <strong>E-money</strong> means the electronic money associated with your Account.
          </p>
          <p>
            <strong>Faster Payment</strong> means a service allowing you to make and receive
            electronic payments in the UK in GBP, which are received by the recipient bank within 2
            hours, provided that the receiving organisation or bank is part of the Faster Payments
            scheme.
          </p>
          <p>
            <strong>Future Dated Payments</strong> means individual, one-off, Payments set up to be
            made at a future date which will be received by the recipient bank on the day specified.
          </p>
          <p>
            <strong>High Value Payment</strong> means a bank transfer of funds into the Account or a
            payment received that is higher than those permitted by the Account Limits.
          </p>
          <p>
            <strong>Information</strong> means any information related to your Account.{' '}
          </p>
          <p>
            <strong>Passcode</strong> means the passcode or thumbprint or other method you use to
            identify yourself and which is used for authorising any payments from the Account on the
            App.
          </p>
          <p>
            <strong>Payment</strong> means a payment made via Faster Payments, Direct Debit, BACS
            Credit or CHAPS.
          </p>
          <p>
            <strong>Payment Details</strong> means the details you provide to enable funds to be
            received into your Account or the details that you provide in order to send funds from
            your Account.
          </p>
          <p>
            <strong>Payment Initiation Services</strong> means an online service which accesses the
            App to initiate a Payment Instruction on your behalf.
          </p>
          <p>
            <strong>Payment Initiation Service Provider</strong> means a third-party payment service
            provider which is authorised by its Regulator to provide Payment Initiation Services
            with your explicit consent and under a separate agreement which you have signed with
            them.
          </p>
          <p>
            <strong>Payment Instruction</strong> means an instruction from you or a Payment
            Initiation Service Provider to make a Payment from your Account.
          </p>
          <p>
            <strong>PPS</strong> means PrePay Technologies Ltd, a company registered in England and
            Wales with number 04008083, with its head office at 6th floor, 3 Sheldon Square, London
            W26HY, which can be contacted at PO BOX 3883 Swindon SN3 9EA.
          </p>
          <p>
            <strong>Programme Manager</strong> means Sprive Limited, a company registered in England
            and Wales with number 12102682 with its head office Cannon Place, 78 Cannon Street, 
            London, EC4N 6AF.
          </p>
          <p>
            <strong>Programme Manager Terms</strong> means the terms and conditions between you and
            the Programme Manager which you agreed to when agreeing to this Agreement, a copy of
            which can be found on the App.
          </p>
          <p>
            <strong>Regulator</strong> means the Financial Conduct Authority in the UK or another
            European financial services regulator.
          </p>
          <p>
            <strong>Sort Code</strong> means a six-digit number which can be found immediately to
            the left of your Account Number in your App.
          </p>
          <p>
            <strong>Standing Order</strong> means regular or recurring payments made to a particular
            person or organisation.
          </p>
          <p>
            <strong>Strong Customer Authentication</strong> means additional security measures to
            verify that you have authorised certain Transactions or other actions related the use of
            your Account.
          </p>
          <p>
            <strong>We, Us, Our</strong> means PPS
          </p>
          <p>
            <strong>Website</strong> means www.sprive.com
          </p>
          <p>
            <strong>Working Day</strong> means Monday to Friday, but not including bank or public
            holidays in England.
          </p>
          <p>
            <strong>You or Your</strong> means you, the person that has entered into this Agreement
            with PPS.
          </p>
        </ol>
      </p>
      <h4>2. Account Limits</h4>
      <p>
        Subject to any further risk assessment requirements, your Account is subject to the
        following Account Limits. We can change limits at our discretion at any time to comply with
        our regulatory obligations and to reduce the risk of financial crime. You can contact
        Customer Services at any time to ask about the limits applicable to your account.
      </p>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th style={{ width: '35%' }} />
              <th colSpan="2" style={{ width: '35%' }}>
                <p>
                  <strong>Limit</strong>
                </p>
              </th>
              <th colSpan="2" style={{ width: '30%' }}>
                <p>
                  <strong>Notes</strong>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>
                  <span>Account Maximum Balance</span>
                </p>
              </td>
              <td colSpan="2">
                <p>
                  <span>&pound;10K</span>
                </p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p>
                  <span>Maximum Load per Week</span>
                </p>
              </td>
              <td colSpan="2">
                <p>
                  <span>&pound;5K</span>
                </p>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <p>
                  <span>Maximum Amount per Load</span>
                </p>
              </td>
              <td colSpan="2">
                <p>
                  <span>&pound;5K</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Subject to foregoing load limits</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Load Methods</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Minimum</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Maximum</span>
                </p>
              </td>
              <td rowSpan="5" />
            </tr>
            <tr>
              <td />
              <td />
              <td />
            </tr>
            <tr>
              <td>
                <p>
                  <span>Faster Payments In</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&pound;0.01</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&pound;5K</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Faster Payments Out</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&pound;0.01</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&pound;10K</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>BACS Credit</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&pound;0.01</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&pound;5K</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Bank transfer</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&pound;0.01</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&pound;5K</span>
                </p>
              </td>
            </tr>
            <tr>
              <td />
              <td colSpan="2" />
              <td colSpan="2" />
            </tr>
          </tbody>
        </table>
      </div>
      <h4>3. Scope of this agreement</h4>
      <ol>
        <p>
          3.1 The Account is an electronic money product and the electronic money associated with it
          is provided by us. We are regulated by the Financial Conduct Authority for the issuance of
          electronic money (FRN 900010). The Programme Manager is registered with the Financial
          Conduct Authority as our agent in relation to the services provided under this Agreement.
        </p>
        <p>
          3.2 This Agreement is written and available only in English and we undertake to
          communicate with you in English regarding any aspect of your Account or Payments.
        </p>
        <p>
          3.3 You agree that we may communicate with you by e-mail or via the App for issuing any
          notices or information about your Accounts or Payments and therefore it is important that
          you ensure you keep your e-mail address updated via the App.
        </p>
        <p>
          3.4 If you wish to make use of services provided by an Authorised Third Party Provider on
          your Account, you may do so provided that you have signed up to use the App and your
          Account is active. We advise that before using an Authorised Third Party Provider, you
          ensure that the Authorised Third Party Provider is authorised by a Regulator to provide
          their services. In the UK, the Financial Conduct Authority’s register (available at{' '}
          <a href="https://register.fca.org.uk" target="_blank" rel="noopener noreferrer">
            https://register.fca.org.uk
          </a>
          ) will tell you whether a company is authorised. You must provide your explicit consent or
          share your App credentials with the Authorised Third Party Provider each time an access to
          your Account is required for them to provide you with their services. You should always
          consider the implications of sharing your App credentials and your personal information.
        </p>
        <p>
          3.5 If an Authorised Third Party Provider requests access to your Account to provide you
          with their services using your App credentials, we will assume that you have given consent
          to do so. Please note we are obliged to provide access to your Account if it is requested
          by an Authorised Third Party Provider and can only refuse access in certain circumstances.
        </p>
        <p>
          3.6 If you do not wish to use services provided by an Authorised Third Party Provider on
          your Account, you simply refuse to provide your consent or refuse to share your App
          credentials with an Authorised Third Party Provider.
        </p>
      </ol>
      <h4>4. Applying and registering your account</h4>
      <ol>
        <p>
          4.1 To open an Account and be able to execute Payments, you must be a UK resident who is
          at least 18 years old.
        </p>
        <p>
          4.2 We will open your Account on the basis of the Information that has been provided by
          you. You must ensure that you provide accurate Information and tell us of any changes as
          soon as possible so that our records remain correct.
        </p>
        <p>
          4.3 If we discover that the information we hold about you is incorrect, we may have to
          suspend access to the Account, suspend or cancel your ability to enter into Payments until
          we can establish the correct information, in order to protect us both.
        </p>
        <p>4.4 It is your responsibility to keep us updated of changes to your details.</p>
      </ol>
      <h4>5. Activating account</h4>
      <ol>
        <p>5.1 Your Account will automatically be available via the App.</p>
        <p>
          5.2 You are responsible for the use of your Account and any actions taken in relation to
          your account under this Agreement and any fees or charges that this may incur.
        </p>
      </ol>
      <h4>6. Using the account</h4>
      <ol>
        <p>
          The Account may be used for setting up Direct Debits, Standing Orders, and to make Faster
          Payments including Future Dated Payments subject to Account Limits. The Account can also
          be used for sending and receiving funds from another Account.
        </p>
      </ol>
      <h4>7. Adding funds to the account</h4>
      <ol>
        <p>
          7.1 Subject to the Account Limits, funds can be added to your Accounts via bank transfer
          (including Faster Payments, BACS Credit and CHAPS payments) and debit or credit card. The
          Account Details will be required for adding funds via one of these methods. Depending on
          how the funds are added to the Account, fees may apply (see clause 13 for more
          information).
        </p>
        <p>
          7.2 A payment coming into the Account may not be credited to the Account if:
          <ol>
            <p>
              7.2.1 the Account has reached the Account Maximum Balance or has exceeded the Account
              Limits; or
            </p>
            <p>7.2.2 the funds exceed maximum cash deposit amounts that is permitted; or</p>
            <p>7.2.3 the Account is inactive or blocked; or</p>
            <p>
              7.2.4 the sender has provided incorrect/invalid Account Details for your Account; or
            </p>
            <p>7.2.5 we suspect fraudulent activity on the Account; or</p>
            <p>7.2.6 to do so is prohibited by any law.</p>
          </ol>
        </p>
        <p>
          7.3 The funds may be sent back to the sender or without notifying you if paragraph 7.2
          applies.
        </p>
        <h4>Payments out</h4>
        <p>
          7.4 The funds may be sent back to the sender or without notifying you if paragraph 7.2
          applies.
        </p>
        <p>
          7.5 Faster Payment transfer to and from the Account, can only be transfers to and between
          the same individual between his/her other accounts held.
        </p>
        <h4>Direct debits</h4>
        <p>
          7.6 To set up a Direct Debit from the Account, you must first authorise the organisation
          taking Direct Debit payments from the Account.
        </p>
        <p>
          7.7 If a Direct Debit on the Account specifies that a payment is to take place on a
          specified day or on the last day of a certain period, then we will treat the Payment
          Instruction as being received on the day specified.
        </p>
        <p>
          7.8 Any Direct Debit payment is usually taken from the Account at the beginning of the
          Working Day it is due.
        </p>
        <p>
          7.9 It is your responsibility to check there is sufficient Available Balance before any
          payment is due. If the Account does not have sufficient Available Balance any Direct Debit
          payment will be rejected.
        </p>
        <h4>Timing of payments</h4>
        <p>7.10 Any payments will be made within the following timescales:</p>
        <div className="table-wrapper">
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <strong>Type of Transaction</strong>
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Timescale</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Faster Payments out</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>Will be sent within 2 hours</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <span>Incoming payment to Account</span>
                  </p>
                </td>
                <td>
                  <p>
                    <span>
                      Funds will be added to the Account the same Working Day we receive the funds
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          7.11 The time of receipt of a transaction order is when we receive it. You cannot stop a
          transaction after it has been transmitted to us and you have given consent, or when you
          have given your consent to a pre-authorised payment.
        </p>
        <h4>Authorisation for and stopping payments</h4>
        <p>
          7.12 It is each your responsibility to ensure correct Payment Details are provided when
          making any Payments or setting up a payee. You are responsible if you give us incorrect
          instructions or mistakenly instructs us to make the same Payment more than once, but we
          will try to help you get the money back. We may not always be able to do this, but we will
          do all we can. We may charge you a fee for tracing, recalling or cancelling a Payment. We
          will tell you how much it is before we start the recovery process. If we cannot get the
          money back, you can request the relevant information we have about the Payment to help you
          reclaim the money. We will provide this information on receiving a written request from
          you, unless the law prevents us doing so.
        </p>
        <p>
          7.13 You can authorise us to make a Payment from your Account via the App by setting up
          electronic payments and giving us instructions via a third party, such as through a direct
          debit scheme or a Payment Initiation Service Provider. We will treat a Payment as
          authorised by you if:
          <ol>
            <p>
              7.13.1 the Payment was authorised from the App using the required Passcode or other
              required credentials;
            </p>
            <p>
              7.13.2 you have set up or agreed to any Automatic Payments to be taken from your
              Account; or
            </p>
            <p>
              7.13.3 a Payment Initiation Service Provider has instructed a Payment from your
              Account which you provided consent for.
            </p>
          </ol>
        </p>
        <p>
          7.14 We may refuse to execute or process a Payment (without prior notice to you) if:
          <ol>
            <p>
              7.14.1 the Account does not have sufficient Available Balance to cover the payment; or
            </p>
            <p>7.14.2 the Account is suspended or closed; or</p>
            <p>7.14.3 the Account has reached its Account Limits; or</p>
            <p>7.14.4 we need to do so to comply with the rules of the payment system; or</p>
            <p>
              7.14.5 we suspect fraudulent activity on the Account or the payment is unlawful or
              fraudulent; or
            </p>
            <p>
              7.14.6 we are concerned about fraud or unauthorised access to the Account by a Payment
              Initiation Service Provider; or
            </p>
            <p>7.14.7 we are required to comply with any law.</p>
          </ol>
        </p>
        <p>
          7.15 If we refuse to process a payment under paragraph 7.14 we will not be liable for any
          loss this may result in, nor will we be obliged to inform the intended recipient. You can
          check the Account to ensure there was enough Available Balance and/or that correct
          recipient details were provided or can ask us why we have not executed a Payment by
          contacting Customer Services. Unless the law prevents us, we will explain why and we will
          also tell you what can be done to correct any errors in the Payment Instruction because we
          or the Programme Manager are concerned under paragraph 7.14.6. We will contact you (which
          may include using the contact details provided by you) as soon as possible or in advance
          if possible to explain why we have refused to process the payment, unless we are
          prohibited by the law or we have security reasons to do so.
        </p>
        <p>
          7.16 You may not be able to stop any Payment once it has been authorised. However, you may
          be able to stop a Direct Debit payment provided that:
          <ol>
            7.16.1 the Direct Debit is cancelled at any time up to end of the Working Day proceeding
            the day agreed for debiting the funds; and
          </ol>
          <ol>7.16.2 the payment has not already been made.</ol>
        </p>
        <p>
          7.17 If you stop or cancel a Direct Debit, you must tell the recipient to whom the Direct
          Debit is payable. We will not be responsible if you fail to inform them and the recipient
          will not have any claim against us.
        </p>
        <p>
          7.18 You may stop a Standing Order and/or a Future Dated Payment by cancelling the payment
          on the App before the end of the Working Day prior to the Working Day the Account is due
          to be debited. We recommend that the recipient is also informed; we will not be
          responsible if you fail to inform them and the recipient will not have any claim against
          us.
        </p>
        <p>
          7.19 If, for any reason, a payment is processed for an amount greater than the Available
          Balance on your Account, you must repay us the amount by which the Full Deductible Amount
          exceeds your Available Balance immediately after receiving notice from us. Should you not
          repay this amount immediately after receiving an invoice from us we reserve the right to
          take all steps necessary, including legal action and/or closing your Account, to recover
          any monies outstanding.
        </p>
        <p>7.20 The Available Balance on your Account will not earn any interest.</p>
      </ol>
      <h4>8. Checking transactions</h4>
      <ol>
        <p>
          8.1 You can check your Available Balance on the Account and Payment history via the App.
          Your monthly Account statements will be made available on the App.
        </p>
      </ol>
      <h4>9. Cancelling, closing your account, and redeeming e-money</h4>
      <ol>
        <p>
          9.1 You may cancel your Account before activating it, and up to 14 calendar days after the
          date of activation ("cancellation period"), by contacting Customer Services. You may
          transfer any Available Balance to a UK bank account via Faster Payments (subject to
          Account Limits) before cancelling the Account.
        </p>
        <p>
          9.2 You may close your Account at any time after the cancellation period and redeem any
          Available Balance on the Account by contacting Customer Services. Alternatively, you may
          at any time transfer any Available Balance to a UK bank account via Faster Payments
          (subject to Account Limits) and call Customer Services to close the Account.
        </p>
        <p>
          9.3 Any Available Balance remaining on the Account following closure will remain available
          to you for a period of six years from the cancellation date. You can redeem the Available
          Balance by contacting Customer Services. We will not return any funds remaining on the
          Account after six years from cancellation of your Account and this Agreement will
          terminate.
        </p>
        <p>
          9.4 Once the Available Balance is redeemed and the Account is closed, your Agreement will
          terminate. However, if paragraph 10.6 applies to your Account then your Account will
          remain active and your Agreement will continue to apply to you until there is no money
          outstanding on your Account.
        </p>
        <p>
          9.5 All Direct Debit transactions that were set up on the Account will be rejected once
          your Account is closed.
        </p>
        <p>
          9.6 If we find any additional withdrawals, fees or charges have been incurred on your
          Account following the processing of the redemption request we will require you to refund
          us immediately upon receiving notice from us. Should you not repay this amount immediately
          after receiving an invoice from us we reserve the right to take all steps necessary,
          including legal action, to recover any monies outstanding.
        </p>
      </ol>
      <h4>10. Your liability and authorisations</h4>
      <ol>
        <p>
          10.1 You are responsible for the use of your Account and Payment Instructions issued. You
          must ensure that any users of your Account understand and comply with this Agreement.
        </p>
        <p>
          10.2 We may restrict or refuse to authorise any use of your Account including transactions
          in any legal jurisdiction if using your Account is causing or could cause a breach of this
          Agreement or if we have reasonable grounds for suspecting that either you or a third party
          has committed or is about to commit a crime or other abuse in connection with the Account.
        </p>
        <p>
          10.3 It is your responsibility to keep safe any security information or credentials
          related to the Account and the App.
        </p>
        <p>
          10.4 You will be liable for all unauthorised Transactions and Payments that arise from the
          use of a lost or stolen Account security information or the misappropriation of the App or
          if you fail to:
          <ol>
            <p>10.4.1 keep the security features of the Account safe.</p>
          </ol>
        </p>
        <p>
          10.5 You must ensure you do not:
          <ol>
            <p>
              10.5.1 allow another person to use the Passcode or security information related to the
              App.
            </p>
            <p>
              10.5.2 write down Passcode(s), PIN or any security information related to the Account
              and App unless this is done in a way that would make it impossible for anyone else to
              recognise any of that information, or
            </p>
            <p>
              10.5.3 disclose their PIN, Passcode or password or any other security information
              related to the Account and App, or otherwise make them available to any other person,
              whether verbally or by entering them in a way that allows them to be observed by
              others.
            </p>
          </ol>
        </p>
        <p>
          10.6 You should never allow a third party other than an Authorised Third Party Provider to
          use or access your Account
        </p>
        <p>
          10.7 You must not disclose or make available your App credentials to a third party unless
          the third party is an Authorised Third Party Provider and you want to use their services.
        </p>
        <p>
          10.8 It is your responsibility to keep us updated of changes to your e-mail address and
          Failure to do so may result in us being unable to contact you regarding your Account,
          including the provision of refunds to which you might be entitled or to let you know about
          changes to this Agreement.
        </p>
        <p>
          10.9 To the extent permitted by law, you agree to indemnify and hold harmless, us and our
          distributors, partners, agents, sponsors, and service providers and their group companies
          from and against the costs of any legal action taken to enforce this Agreement where you
          are at fault and/or any breach of this Agreement or fraudulent use of the Account, App log
          in details, or PIN or Passcode by or authorised by you.The App is only supported on
          devices where the operating system has not been modified, or jailbroken, or configured to
          allow software installation from sources other than those approved by the Programme
          Manager (including but not limited to the Apple App Store and Google Play). Use of the App
          on such a device is at your risk and neither PPS nor Sprive can be held responsible for
          any loss or data, Information or financial loss.
        </p>
      </ol>
      <h4>11. Unauthorised and incorrectly executed payments</h4>
      <ol>
        <p>
          11.1 If you have a reason to believe that a Payment on your Account was unauthorised by
          you or a Payment Initiation Service Provider or was made incorrectly, you must ensure that
          you inform us immediately via the App or by contacting Customer Services, but in any event
          within 13 months of the date of the relevant transaction.
        </p>
        <p>
          11.2 If an unauthorised Payment is reported to us:
          <ol>
            <p>
              11.2.1 Subject to clauses 12.2.2 and 12.3 we will by the end of the next Working Day
              refund the unauthorised amount including any fees to your Account and return it to the
              position it would have been in if the unauthorised Payment had not taken place.
            </p>
            <p>
              11.2.2 we are not obliged to refund the unauthorised sums to you if we have reason to
              believe that you have acted fraudulently, and we may notify the police or any other
              authority permitted by law. If we don’t provide a refund by the end of the next
              Working Day but subsequently confirm that the Payment was unauthorised, we will refund
              the unauthorised sums to you straight away.
            </p>
            <p>
              11.2.3 we will have no further liability to you once we have refunded the unauthorised
              sums to you. If we subsequently discover that you were not entitled to a refund, we
              shall treat the refund as a mistake and be entitled to reapply the transaction,
              including any fees, to your Account.
            </p>
          </ol>
        </p>
        <p>
          11.3 You will be liable for all unauthorised Payments made from your Account if you have
          acted fraudulently or deliberately or with gross negligence failed to keep the security
          information or App credentials safe in accordance with this Agreement, or where you have
          failed to notify us without undue delay on becoming aware tha security information related
          to the App credentials has been lost or stolen.
        </p>
        <p>
          11.4 You will not be liable for unauthorised Payments that occur after you have told us
          that the App credentials have been lost, stolen or compromised.
        </p>
        <p>
          11.5 If you tell us that a Payment has been made incorrectly, after we are informed, we
          will immediately refund your Account with sufficient funds including any charges to
          restore your Account to the same position as if the incorrect Payment had not been made.
          However, this will not apply if:
          <ol>
            <p>
              11.5.1 you fail to tell us of the incorrect Payments without undue delay and in any
              case within 13 months of the date on which the transaction occurred;
            </p>
            <p>
              11.5.2 any Payment Instructions you gave us were incorrect. If so, we will make
              reasonable efforts to recover your money if the payment has gone missing, but we may
              charge you a fee to cover our costs in doing so. We will tell you how much it is
              before we start recovery process. If we are unable to recover the funds, you can
              request the relevant information we have regarding the transaction to help you reclaim
              the payment amount. For legal reasons, we are obliged to provide this information on
              receipt of a written request, except where the law prevents us from doing so; or
            </p>
            <p>
              11.5.3 we can show that the payment was actually received by the other bank (in which
              case they will be liable).
            </p>
          </ol>
        </p>
        <p>
          11.6 If funds have been paid in to your Account by mistake.
          <ol>
            <p>
              11.6.1 we can take the funds back out of your Account and/or put a hold on the money
              so it cannot be spent.
            </p>
            <p>
              11.6.2 we don’t have to tell you before we take the money back or put a hold on the
              money.
            </p>
            <p>
              11.6.3 we are required to provide sufficient details about you and the incorrect
              payment to the bank or institution that sent the payment to enable them to recover the
              funds.
            </p>
          </ol>
        </p>
        <p>
          11.7 If an error is made in Direct Debit, you will be entitled to a refund from the payee
          or us under the Direct Debit Guarantee.
        </p>
        <p>
          11.8 Where you have agreed that another person can take a payment from your Account, you
          can ask us to refund a payment if all the following conditions are satisfied:
          <ol>
            <p>11.8.1 the authorisation given did not specify the exact amount to be paid;</p>
            <p>
              11.8.2 the amount that has been charged to your Account was more than you could
              reasonably have expected to pay, based on the circumstances, including previous
              spending patterns; and
            </p>
            <p>
              11.8.3 you make the refund request within eight weeks of the date when the payment was
              taken from your Account.
            </p>
          </ol>
        </p>
        <p>
          11.9 We may ask you to provide information as is reasonably necessary to verify that
          conditions in paragraphs 12.8.1, 12.8.2 and 12.8.3 apply.
        </p>
        <p>
          11.10 If you ask us to make a refund under paragraph 12.8 then, within 10 Working Days of
          the date we receive your request (or if we ask for more information under paragraph 12.9,
          within 10 Working Days of the date we receive that information) we will either:
          <ol>
            <p>11.10.1 refund the payment in full; or</p>
            <p>11.10.2 tell you the reasons why we do not agree to the refund.</p>
          </ol>
        </p>
        <p>
          11.11 You will not be entitled to a refund under paragraph 12.8 if:
          <ol>
            <p>11.11.1 you have given us your consent for the payment to be made; and</p>
            <p>
              11.11.2 where applicable we (or the person or a Merchant you agreed to pay) have given
              you information on the payment in question at least four weeks before the due date of
              the payment.
            </p>
            <p>
              11.11.3 if the payment in question was higher than you reasonably expected to pay due
              to a change in any currency exchange rate.
            </p>
          </ol>
        </p>
        <p>
          11.12 Paragraph 12.11 does not limit your rights under the Direct Debit Guarantee Scheme.
        </p>
      </ol>
      <h4>12. Variation</h4>
      <ol>
        <p>
          12.1 We may change this Agreement, including fees and limits by providing you with at
          least two months prior notice via the App and/or by e-mail (provided you have supplied us
          with an up- to-date e-mail address) and will ensure the most recent version is always
          available on the App. PPS may need to make such changes for reasons including but not
          limited to the following:
          <ol>
            <p>
              12.1.1 to reflect the introduction or development of new systems, methods of
              operation, services or facilities;
            </p>
            <p>
              12.1.2 to reflect a change or an expected change in market conditions, general good
              practice or the cost of providing our services to our customers;
            </p>
            <p>
              12.1.3 to conform with or anticipate any changes in the law or taxation, any codes of
              practice or recommendations of the Financial Conduct Authority or other regulatory
              body;
            </p>
            <p>12.1.4 to ensure that our business is run prudently and remains competitive;</p>
            <p>
              12.1.5 to take account of a ruling by a court, ombudsman, regulator or similar body;
            </p>
            <p>12.1.6 to make the terms and conditions fairer or clearer for you;</p>
            <p>12.1.7 to rectify any mistake that might be discovered in due course; or</p>
            <p>12.1.8 by agreement with you; or</p>
            <p>12.1.9 to enable us to harmonise our banking interest or charging arrangements</p>
          </ol>
        </p>
        <p>
          12.2 If you do not agree with the changes to the Agreement, you may at any time within the
          two months’ notice period terminate this Agreement and close your Account in accordance
          with paragraph 10.2 at that time without a charge. However, in the event you do not cancel
          during this period then you will be deemed to have accepted them and the changes will
          apply to you.
        </p>
        <p>
          12.3 If any part of this Agreement is inconsistent with any regulatory requirements then
          we will not rely on that part but treat it as if it did actually reflect the relevant
          regulatory requirement. If we need to make operational changes before we can fully comply
          with the new regulatory requirement, we will make those changes as soon as reasonably
          practical. We will update our Agreement to reflect the new regulatory requirements when
          they are next reprinted.
        </p>
      </ol>
      <h4>13. Termination or Suspension</h4>
      <ol>
        <p>
          13.1 We can terminate this Agreement at any time:
          <ol>
            <p>
              13.1.1 if we give you two months’ notice and refund the Available Balance to you
              without charge, or
            </p>
            <p>
              13.1.2 with immediate effect if you have breached this Agreement, or if we have reason
              to believe that you have used, or intend to use the Account in a grossly negligent
              manner or for fraudulent or other unlawful purposes or if we can no longer process
              your transactions due to the actions of third parties.
            </p>
          </ol>
        </p>
        <p>
          13.2 We can suspend or terminate any Account at any time with immediate effect (and until
          your default has been remedied or the Agreement terminated) without any prior notice to
          you if:
          <ol>
            <p>
              13.2.1 we discover any of the Information that you have provided to us in relation to
              your Account is incorrect; or
            </p>
            <p>13.2.2 we believe that this is necessary for security reasons; or</p>
            <p>
              13.2.3 we suspect or to prevent suspected unauthorised or fraudulent use of any
              Account or any security credentials related to any Account; or
            </p>
            <p>13.2.4 any legal obligations require us to do so; or</p>
            <p>
              13.2.5 you have breached this Agreement or we have reason to believe that you have
              used, or intend to use the Account or the App or the Passcode in a grossly negligent
              manner or for fraudulent or other unlawful purposes or if we cannot process any
              transactions due to the actions of third parties.
            </p>
          </ol>
        </p>
        <p>
          13.3 In the event that we do suspend or terminate any Accounts then if we are able to do
          so, we will tell you in advance otherwise we will let you know immediately afterwards (to
          the extent the we are permitted by law). We may advise anyone involved in the transaction
          if a suspension has taken place.
        </p>
        <p>
          13.4 In the event that any additional fees are found to have been incurred on your Account
          following termination by either you or us, then subject to this Agreement, you shall
          refund to us any sum which relates to a withdrawal on the Account or fees and/or charges
          validly applied whether before or after termination. We will send a notification to you
          and will require you to refund us immediately. Should you not repay this amount
          immediately after receiving notice from us we reserve the right to take all steps
          necessary, including legal action, to recover any monies outstanding.
        </p>
      </ol>
      <h4>14. Our Liability</h4>
      <ol>
        <p>
          14.1 To the extent permitted by law, our liability in connection with this Agreement
          (whether arising in contract, tort (including negligence), breach of statutory duty or
          otherwise) shall be subject to the following exclusions and limitations:
          <ol>
            <p>
              14.1.1 we shall not be liable for any default resulting directly or indirectly from
              any cause beyond our control, including but not limited to, a lack of funds and/or
              failure of network services at ATMs, maximum withdrawal limits set by ATM operators
              and failure of data processing systems;
            </p>
            <p>
              14.1.2 we shall not be liable for any loss of profits, loss of business, or any
              indirect, consequential, special or punitive losses;
            </p>
            <p>14.1.3 or at our choice, redemption of the Available Balance;</p>
            <p>
              14.1.4 where sums are incorrectly deducted from your Available Balance due to our
              default, our liability shall be limited to payment to you of an equivalent amount;
            </p>
            <p>
              14.1.5 in the unlikely event that sums are deducted from your Available Balance but
              you did not authorised such deduction in accordance with this Agreement then our
              liability shall be as set out in paragraph 12; and
            </p>
            <p>
              14.1.6 in all other circumstances of our default, our liability will be limited to
              redemption of the Available Balance.
            </p>
          </ol>
        </p>
        <p>
          14.2 Nothing in this Agreement shall exclude or limit our liability for death or personal
          injury resulting from our negligence or fraud or any other liability which may not be
          limited or excluded by law.
        </p>
        <p>
          14.3 To the extent permitted by law, all conditions or warranties implied by law, statute
          or otherwise are expressly excluded. the Programme Manager has no liability to you under
          or in connection with the Account or this Agreement.
        </p>
        <p>
          14.4 The above exclusions and limitations set out in this paragraph shall apply to any
          liability of our affiliates and other suppliers, contractors, agents or distributors and
          any of their respective affiliates (if any), to you, which may arise in connection with
          this Agreement.
        </p>
        <p>
          14.5 In the event of suspected or actual fraud or security threat to any Account, we will
          use email or another secure procedure to contact you. We may ask you to verify your
          identity for security purposes.
        </p>
      </ol>
      <h4>15. Your Information</h4>
      <ol>
        <p>
          15.1 Some personal data will be necessary for us to provide you with the Account and
          services under this Agreement, we are the Data Controller and only use your personal data
          for this purpose. Please see the Privacy Policy published on the website for full details
          on the personal data that we hold, how we will use it and how we will keep it safe.
        </p>
        <p>
          15.2 If you allow or give consent to an Authorised Third Party Provider to access your
          Account to provide their services to you, you should know that we have no control over how
          an Authorised Third Party Provider will use your information nor will we be liable for any
          loss of information after an Authorised Third Party Provider has access to your
          information.
        </p>
      </ol>
      <h4>16. Complaints Procedure</h4>
      <ol>
        <p>
          16.1 To notify us of a complaint regarding any element of the service provided by us
          please contact Customer Services.
        </p>
        <p>
          16.2 All complaints will be subject to our complaint’s procedure. We will provide you with
          a copy of our complaints procedure upon request and, if we receive a complaint from you, a
          copy of our complaints procedure will automatically be posted to you.
        </p>
        <p>
          16.3 If we fail to resolve your complaint to your satisfaction you may be able to refer
          your complaint to the Financial Ombudsman Service (Exchange Tower, London E14 9SR, phone
          0800 023 4567). Details of the service offered by the Financial Ombudsman Service are
          available at{' '}
          <a href="https://financial-ombudsman.org.uk" target="__blank" type="email">
            www.financial-ombudsman.org.uk
          </a>
          .
        </p>
      </ol>
      <h4>17. General</h4>
      <ol>
        <p>
          17.1 Any delay or failure to exercise any right or remedy under this Agreement by us shall
          not be construed as a waiver of that right or remedy or preclude its exercise at any
          subsequent time.
        </p>
        <p>
          17.2 If any provision of this Agreement is deemed unenforceable or illegal, the remaining
          provisions will continue in full force and effect.
        </p>
        <p>
          17.3 You may not assign or transfer any of your rights and/or benefits under this
          Agreement and you shall be the sole party to the contract between us. You will remain
          liable until all Accounts issued to you are terminated and all sums due under this
          Agreement have been paid by you in full. We may assign our rights and benefits at any time
          without prior written notice to you. We may subcontract any of our obligations under this
          Agreement. We may also permanently transfer our rights and obligations under this
          Agreement to another account, or cease to provide the Account, by giving you 2 months'
          notice in writing – this is most likely to occur if the Programme Manager decides to cease
          to operate Account through the App or if it changes the provider of account associated
          with the App.
        </p>
        <p>
          17.4 No third party who is not a party to this Agreement has a right to enforce any of the
          provisions in this Agreement.
        </p>
        <p>
          17.5 This Agreement contains the information set out in Schedule 4 of the Payment Service
          Regulations 2017 and you can obtain, and download and print, a copy of this Agreement at
          any time by visiting the App or the Website.
        </p>
        <p>
          17.6 This Agreement is governed by English law and you agree to the exclusive jurisdiction
          of the courts of England and Wales.
        </p>
        <p>
          17.7 The Financial Services Compensation Scheme is not applicable for this Account. No
          other compensation schemes exist to cover losses claimed in connection with your Account.
          We will however safeguard your funds against any loss that can be reasonably anticipated.
        </p>
      </ol>
      <h4>18. Contacting Customer Services</h4>
      <ol>
        <p>
          If you have a query regarding your Account or a Payment, you can contact{' '}
          <a href="mailto:hello@sprive.com" type="email">
            hello@sprive.com
          </a>
          .
        </p>
      </ol>
    </div>
  </section>
);

export default PrivacyPage;
